import React, { Component } from 'react';
import Layout from '../components/Layout.js';
import PostsList from '../components/BlogPost/PostsList.js';

class Blog extends Component {
  render() {
    return (
        <Layout>
          <PostsList/>
        </Layout>
    );
  }
}

export default Blog;
